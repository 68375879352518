<template>
    <div class="page-404">
        <img src="../../assets/images/BIZ_ERROR.png" alt="">
        <p>访问失败，请稍后重试！</p>
    </div>
</template>
 
<script>
 
export default {
    data() {
        return {}
    },
   methods: {
        
    }
}
</script>
 
<style scoped>
 .page-404{
     height: 100vh;
     width: 100vw;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }
</style>